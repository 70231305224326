<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        重磅课程
      </template>
      <template #input>
        <a-button class="all_boder_btn" @click="relationFilling">新建</a-button>
      </template>
    </HeaderBox>

    <div class="none" v-if="!anchorList.length">
      暂无数据~
    </div>

    <!-- 配置列表 -->
    <ul class="configs">
      <li class="configItem" v-for="(item,index) in anchorList" :key="index">
        <p class="title">标题:</p>
        <div class="content">
          <div class="function">
            <a-input placeholder="" :readOnly="true" v-model="item.title" />
            <a-button type="primary" @click="onEditChange(item)">编辑</a-button>
            <a-button class="all_boder_btn" @click="delate = true,chiefEditorId = item.chiefEditorId">删除</a-button>
          </div>
          <a-table class="table-template" 
              :columns="columns" 
              :loading="loading" 
              :rowKey="item=>item.chiefProductId"
              :data-source="item.productVOList" 
              :locale="{emptyText: '暂无数据'}" 
              :pagination="false" >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
            <!-- 排序 -->
             <template slot="orderSort" slot-scope="inem, row">
              <a-input-number id="inputNumber"  v-model="row.orderSort" :min="0"  @change="changesort(row)" />
            </template>
            <!-- 商品类型 -->
            <template slot="productType" slot-scope="item">
              <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
              <span v-if="item==1">课程</span>
              <span v-else-if="item==2">图书</span>
              <span v-else-if="item==3">教具</span>
              <span v-else-if="item==4">模拟考试</span>
              <span v-else-if="item==5">电子照片</span>
              <span v-else-if="item==6">延期</span>
              <span v-else-if="item==7">补考</span>
              <span v-else-if="item==8">直播</span>
              <span v-else-if="item==9">其他</span>
              <span v-else>-</span>
            </template>
            <template slot="operation" slot-scope="item, row">
              <span class="blueText">
                <a-popconfirm title="确定删除嘛？" @confirm="confirm(row.chiefProductId)" ok-text="确定" cancel-text="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </template>
          </a-table>
        </div>
      </li>
    </ul>

    <!-- 新建编辑弹窗 -->
    <a-modal v-model="relation" width='1000px' :title="form.chiefEditorId ? '编辑重磅课程' : '新建重磅课程'"  :centered='true' :closable='false'>
      <div class="name-box">
        <span class="name all_required">标题：</span>
        <a-input class="input" v-model="form.title" style="width:50%" placeholder="请输入推荐商品分组名称" />
      </div>
      <div class="search-box">
        <div class="left">
          <span>查询商品条件:</span>
          <a-select class="search-input" v-model="productForm.type" placeholder="请选择商品类型" allowClear >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="1">课程</a-select-option>
            <a-select-option :value="2">图书</a-select-option>
            <a-select-option :value="3">教具</a-select-option>
            <!-- <a-select-option :value="4">模拟考试</a-select-option>
            <a-select-option :value="5">电子照片</a-select-option>
            <a-select-option :value="6">延期</a-select-option>
            <a-select-option :value="7">补考</a-select-option>
            <a-select-option :value="8">直播</a-select-option>
            <a-select-option :value="9">其他</a-select-option> -->
          </a-select>
          <a-input class="search-input" v-model="productForm.name" placeholder="请输入商品名称" />
        </div>
        <a-button class="search-btn" type="primary" @click="onProductSearch">搜索</a-button>
      </div>

      <div class="table-box">
        <a-table class="table-template1" 
          :row-selection="{
            columnTitle:'选择',
            selectedRowKeys: selectedProductRowKeys,
            onChange: onSelectProduct,
            columnWidth:'70px'
          }" 
          bordered
          :rowKey="item=>item.productId"
          :columns="productcolumns" 
          :data-source="productData"
          :loading="productLoad"
          @change="onProductPage"
          :pagination="{
            total:productTotal,
            current:productForm.page,  
            defaultpageSize:productForm.size, 
            showSizeChanger: true,
            showTotal: function(productTotal, range){
              return `共${productTotal}条`
            }
          }">
          <template slot="index" slot-scope="item, row, index">
            {{ (productForm.page - 1) * productForm.size + index + 1 }}
          </template>
         
          <!-- 商品类型 -->
          <template slot="productType" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item==1">课程</span>
            <span v-else-if="item==2">图书</span>
            <span v-else-if="item==3">教具</span>
            <span v-else-if="item==4">模拟考试</span>
            <span v-else-if="item==5">电子照片</span>
            <span v-else-if="item==6">延期</span>
            <span v-else-if="item==7">补考</span>
            <span v-else-if="item==8">直播</span>
            <span v-else-if="item==9">其他</span>
            <span v-else>-</span>
          </template>
        </a-table>

        <a-table 
          class="table-template2" 
          style="width:30%"
          bordered
          :rowKey="item=>item.productId"
          :columns="selectcolumns" 
          :data-source="selectData" 
          :pagination="{
            total: selectTotal,
            showTotal: (res) => `共${selectTotal}条`,
          }">

          <template slot="num">
            当前已选中 <span class="blueText">{{selectTotal}}</span> 个商品
          </template>

          <template slot="operation" slot-scope="text,record,index">
            <a @click="onSelectIndexDel(index)" class="blueText">清除</a>
          </template>
          
        </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="btnLoad" @click="relationOk">确认</a-button>
        <a-button @click="relationCancel">取消</a-button>
      </template>

    </a-modal>

    <!-- 删除弹窗 -->
    <a-modal v-model="delate" width='530px' title="删除" @ok="delateOk" :centered='true' :closable='false'>
      <div class="tips">删除该配置后，首页重磅课程将不会有该内容，是否继续删除？</div>
    </a-modal>
  </div>
</template>

<script>
  const productcolumns = [{
      title: "序号",
      align: "center",
      width:'70px',
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      title: "商品类型",
      align: "center",
      width:'140px',
      dataIndex: "productType",
      scopedSlots: {
        customRender: "productType",
      },
    },
    {
      title: "商品名称",
      ellipsis:true,
      align: "center",
      dataIndex: "productName",
    },
  ];
  const selectcolumns = [{
      align: "center",
      dataIndex: "productName",
      width: 120,
      ellipsis:true,
      colSpan: 2,
      scopedSlots: {
        customRender: "productName",
        title: "num",
      },
    },
    {
      title: "操作",
      align: "center",
      colSpan: 0,
      width: 40,
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation",
      },
    },
  ];
  const columns = [{
      title: "序号",
      width: "80px",
      align: "center",
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      title: "商品名称",
      align: "center",
      dataIndex: "productName",
    },
    {
      title: "排序",
      align: "center",
      dataIndex: "orderSort",
      scopedSlots: {
        customRender: "orderSort",
      },
    },
    {
      title: "类型",
      align: "center",
      dataIndex: "productType",
      scopedSlots: {
        customRender: "productType",
      },
    },

    {
      title: "操作",
      align: "center",
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation",
      },
    },
  ];
  import HeaderBox from "@/components/HeaderBox.vue";

  export default {
    // 可用组件的哈希表
    components: {
      HeaderBox,
    },
    // 接收传值
    props: {},
    // 数据对象
    data() {
      return {
        delate: false,
        relation: false,
        selectcolumns,
        productData:[],  // 商品列表
        productcolumns,  // 商品表头
        productTotal:0, // 商品数量
        productLoad:false,
        btnLoad:false,
        selectedProductRowKeys:[],
        productArry:[],  // 已选商品--暂存
        selectData:[],  // 已选商品
        productForm: {
          name:'',
          type:undefined,
          page:1,
          size:10,
        },
        selectTotal: 0,

        chiefEditorId:0,
        form:{
          chiefEditorId:'',
          userId:'',
          title:'',
          productIdList:[]
        },

        // 力荐条件
        queryParams:{
          pageNum:1,
          pageSize:10
        },
        
        ppageNumber: 1, //页码
        ppageSize: 10, // 页数
        columns, // 表头
        tableData: [], // 列表数据
        loading: false, // 列表加载
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 10, // 页数
        expandedRowKeys: [], //选择的id
        anchorList: [],
      };
    },
    // 事件处理器
    methods: {
      // 分页
      onPage(){},

      // 商品分页
      onProductPage(e) {
        this.productForm.page = e.current;
        this.productForm.size = e.pageSize;
        this.getProductList()
      },
      // 跳详情页
      goDetails() {
        this.$router.push({
          path: "/admin/UserManage/userDetail",
          query: {
            id: 1,
          },
        });
      },

      // 删除单个商品
      confirm(id) {
        this.$ajax({
          method: 'DELETE',
          url: '/hxclass-management/chief/del/product?chiefProductId=' + id,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.getManageList()
            this.delate = false;
          } else {
          }
        });
      },

      // 确认添加商品
      relationOk(e) {
        if(!this.form.title){
          this.$message.warning('请填写标题');
          return
        }
        if(!this.selectData.length){
          this.$message.warning('请选择商品');
          return
        }
        this.btnLoad = true
        this.selectData.forEach(element => {
          this.form.productIdList.push(element.productId)
        })
        this.$ajax({
          method: this.form.chiefEditorId ? 'put' : 'post',
          url: this.form.chiefEditorId ? "/hxclass-management/chief/update": "/hxclass-management/chief/add" ,
          params: this.form
        }).then(res => {
          this.btnLoad = false
          if (res.code == 200 && res.success) {
            this.$message.success('添加成功');
            this.getManageList()
            this.relation = false;
          } else{
            this.$message.warning(res.message);
          }
        })
      },

      // 检索力荐列表
      getManageList() {
        this.$ajax({
          method: 'get',
          url: '/hxclass-management/chief/list',
          params: this.queryParams
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.anchorList = res.data.records;
          } else {
            this.anchorList = []
            this.$message.error(res.message);
          }
        });
      },
     relationCancel(e) {
       this.relation = false;
      },

      relationFilling() {
        this.relation = true;
      },
      delateOk() {
        this.$ajax({
          method: 'DELETE',
          url: '/hxclass-management/chief/del?chiefEditorId=' + this.chiefEditorId,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.getManageList()
            this.delate = false;
          } else {
          }
        });
      },
      
      // 商品高级检索
      onProductSearch(){
        this.productForm.page = 1
        this.getProductList()
      },
      // 检索商品列表
      getProductList() {
        this.productLoad = true
        this.$ajax({
          method: 'get',
          url: '/hxclass-management/product-archives/manage/select-win',
          params: this.productForm
        }).then((res) => {
          this.productLoad = false
          if (res.code == 200 && res.success) {
            this.productTotal = res.data.total;
            this.productData = res.data.records;
          } else {
            this.$message.error(res.message);
          }
        });
      },

      // 选择商品
      onSelectProduct(selectedRowKeys,v){
        this.selectedProductRowKeys = selectedRowKeys
        this.productArry[this.productForm.page - 1] = v
        // this.selectData = []
        let storageArry = []
        this.productArry.forEach(item => {
          item.forEach(subItem => {
            storageArry.push(subItem)
          });
        });
        // 已选数组和新数组合并
        this.selectData = [...this.selectData, ...storageArry]
        // console.log(this.selectData)
        // console.log(selectedRowKeys)

        let arry = []
        selectedRowKeys.forEach(element => {
          this.selectData.forEach(item => {
            if(element == item.productId)
              arry.push(item)
            });
        });

        // 并列数组去重
        let newArr = [];
        let obj = {};
        for (var i = 0; i < arry.length; i++) {
          if (!obj[arry[i].productId]) {
            newArr.push(arry[i])
            obj[arry[i].productId] = true
          }
        }

        this.selectData = newArr
        this.selectTotal = this.selectData.length
      },

      // 清除某项已选商品
      onSelectIndexDel(index){
        this.selectData.splice(index,1)
        this.selectedProductRowKeys.splice(index,1)
        this.selectTotal = this.selectData.length
      },

      onEditChange(e){
        this.selectedProductRowKeys = []
        this.form.title = e.title
        this.form.chiefEditorId = e.chiefEditorId
        let arry = JSON.stringify(e.productVOList)
        this.selectData = JSON.parse(arry)
        e.productVOList.forEach(element => {
          this.selectedProductRowKeys.push(element.productId)
        })

        this.selectTotal = this.selectData.length
        this.relation = true;
      },

      // 修改排序
      changesort(e){
        this.productLoad = true
        this.$ajax({
          method: 'put',
          url: '/hxclass-management/chief/updateChiefProductSort?chiefProductId='+e.chiefProductId+"&sort="+e.orderSort,
          // params: {
          //   chiefProductId:e.courseId,
          //   sort:e.orderSort,
          // }
        }).then((res) => {
          this.productLoad = false
          if (res.code == 200 && res.success) {
          //  this.getProductList()
          } else {
            this.$message.error(res.message);
          }
        });
      }

    },
    // 生命周期-实例创建完成后调用
    created() {
      this.getManageList()
      this.getProductList() // 商品列表
      this.form.userId = this.$store.state.userInfo.id
    },
    // 生命周期-实例挂载后调用
    mounted() {},
    // 生命周期-实例销毁离开后调用
    destroyed() {},
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {
      relation(){
        if(!this.relation){
          this.form.title=''
          this.form.chiefEditorId = ''
          this.form.productIdList=[]
          this.selectedProductRowKeys = []
          this.selectData = []
          this.selectTotal = 0
          this.productForm.type = undefined 
          this.productForm.page = 1 
          this.productForm.name = '' 

        } else {
          this.getProductList() // 商品列表
        }
      }
    },
  };

</script>

<style lang="less" scoped>
.none{
  width: 100%;
  margin: 80px 0;
  font-size: 24px;
  text-align: center;
}
  .configs {
    padding-top: 16px;

    .configItem {
      display: flex;
      margin-top: 77px;

      .title {
        width: 67px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 45px;
        padding-right: 19px;
      }

      .content {
        .function {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .ant-input {
            width: 464px;
            height: 45px;
          }

          .ant-btn {
            margin-left: 24px;
          }
        }

        // .operations {
        //   display: flex;
        //   justify-content: center;

        //   .operation {
        //     padding: 0 5px;
        //     border-left: 1px solid #3681f0;
        //     font-size: 12px;
        //     font-family: PingFang SC-Regular, PingFang SC;
        //     font-weight: 400;
        //     color: #3681f0;
        //     line-height: 12px;
        //   }

        //   .operation:first-child {
        //     border: 0;
        //   }
        // }
      }
    }

    .configItem:first-child {
      margin: 0;
    }
  }

  .btns {
    margin-left: 67px;
    margin-top: 64px;
    padding-bottom: 58px;

    .ant-btn {
      margin-right: 19px;
    }
  }

  .table-box {
    /deep/ .ant-tabs-bar {
      border-bottom: none;
    }
  }

  /deep/.ant-modal-body {
    overflow: hidden;
  }

  /deep/.ant-modal-title {
    text-align: center;
    color: #000000;
  }

  /deep/.ant-modal-header {
    border-bottom: none;
  }

  /deep/.ant-modal-footer {
    text-align: center;
    border-top: none;
  }

  /deep/ .ant-modal-body {
    padding: 14px 36px 14px;
  }

  /deep/.ant-tabs-ink-bar-animated {
    position: relative;
    top: -10px;
  }

  .tips {
    font-size: 16px;
    color: #000000;
    text-align: center;
  }

  .table-template {
    /deep/.ant-table {
      border: none;
    }
  }

  .table-box {
    display: flex;
    justify-content: space-between;
    .table-template1{
      width: 600px;
      margin-right: 20px;
    }
    .table-template2{
      flex: 1;
    }
  }

  .name-box{
    display: flex;
    align-items: center;
    margin: 14px 0;
    .name{
      width: 60px;
    }
    // width: 200px;
  }

  .search-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 640px;
    .left{
      width: 90%;
      display: flex;
      align-items: center;
      >span{
        display: inline-block;
        width: 100px;
      }
      .search-input {
        width:36%;
        margin-right: 20px;
      }
    }
  }

</style>
